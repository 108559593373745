import 'twin.macro'

import { yupResolver } from '@hookform/resolvers/yup'
import BodyText from 'components/UI/body-text/body-text'
import { Button } from 'components/UI/button/button'
import { Checkbox } from 'components/UI/checkbox/checkbox'
import Link from 'components/UI/link/link'
import { Modal } from 'components/UI/modal-window/modal'
import { StepWrapper } from 'components/UI/step-wrapper/step-wrapper'
import SubBody from 'components/UI/sub-body/sub-body'
import Title from 'components/UI/title/title'
import OtpInput from 'components/views/otp-verify'
import OtpVerify from 'components/views/otp-verify'
import PdfFileModalViewer from 'components/views/pdf-file-modal-viewer'
import React, { Fragment, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ClipLoader } from 'react-spinners'
import { useGetApplicationDocumentsQuery } from 'redux/api/documents.apt'
import { useOtpVerifyMutation, useSendOtpMutation } from 'redux/api/otp.api'
import { authDataSelector } from 'redux/store/slices/application-slice'
import tw from 'twin.macro'
import * as yup from 'yup'

type OtpSchemaValues = yup.InferType<typeof otpSchema>
type DocumentType = {
  file: string
  uuid: string
  name: string
}
const otpSchema = yup.object({
  code: yup.string().min(4, '').required('Это поле обязательное'),
  agreement: yup.boolean()
})

function SignDoc() {
  const [doc, setDoc] = useState<DocumentType>({ file: '', uuid: '', name: '' })
  const [open, setOpen] = useState<boolean>(false)
  const [isOtpSign, setIsOtpSign] = useState<boolean>(false)

  const { product_user_action_uuid } = useSelector(authDataSelector)

  const { data: documents, isLoading: documentsLoading } = useGetApplicationDocumentsQuery(
    { uuid: product_user_action_uuid ?? '' },
    { skip: !product_user_action_uuid }
  )

  const [sendOtp, { isLoading, isSuccess: sendOtpSuccess }] = useSendOtpMutation()
  const [otpVerify, { isSuccess: otpVerifySuccess }] = useOtpVerifyMutation()

  const {
    control,
    watch,
    formState: { isValid }
  } = useForm<OtpSchemaValues>({
    mode: 'onChange',
    defaultValues: { code: '', agreement: true },
    resolver: yupResolver(otpSchema)
  })
  const { t } = useTranslation('signdoc')
  const navigate = useNavigate()

  const handleOtpVerify = () => {
    otpVerify({ code: watch('code'), otp_type: 'DOCUMENT_VERIFICATION' })
  }

  const handleSendOtp = () => {
    sendOtp({ otp_type: 'DOCUMENT_VERIFICATION' })
  }
  const onClose = () => {
    setOpen(false)
  }

  const downloadDoc = (doc: DocumentType) => {
    const link = document.createElement('a')
    link.href = doc.file
    link.setAttribute('target', '_blank')
    link.setAttribute('download', `${doc.uuid}.pdf`)
    document.body.appendChild(link)
    link.click()
  }
  const openDocument = (doc: DocumentType) => {
    setDoc(doc)
    setOpen(true)
  }

  useEffect(() => {
    sendOtpSuccess && setIsOtpSign(true)
  }, [sendOtpSuccess])

  // useEffect(() => {
  //   otpVerifySuccess && navigate('/success')
  // }, [otpVerifySuccess])

  return (
    <StepWrapper twStyle={tw`text-center flex flex-col justify-center items-center`}>
      <Title variant='bold'>{t('title')}</Title>

      {!isOtpSign && (
        <Fragment>
          {documentsLoading ? (
            <ClipLoader color='#4f9d3a' />
          ) : (
            <div tw='flex flex-col items-center justify-center gap-2 w-full mt-5'>
              {documents?.product?.document_templates.map((item: DocumentType, key: number) => (
                <button
                  tw='bg-primary rounded-xl py-3 w-full capitalize'
                  key={item.uuid}
                  onClick={() => openDocument(item)}
                >
                  {item.name}
                </button>
              ))}
            </div>
          )}
          <Checkbox control={control} name='agreement'>
            <SubBody twStyle={tw`text-left`}>{t('agreement')}</SubBody>
          </Checkbox>
          <Button
            variant='secondary'
            onClick={handleSendOtp}
            loading={isLoading}
            disabled={!watch('agreement') || isLoading || documentsLoading}
          >
            {t('sign_w_otp')}
          </Button>
        </Fragment>
      )}
      {isOtpSign && <OtpVerify control={control} name={'code'} isOtpValid={isValid} onOtpVerify={handleOtpVerify} />}
      <Modal open={open} onClose={onClose}>
        <div tw='flex flex-col space-y-3'>
          {doc && (
            <div tw='ml-4'>
              <Button variant='link' onClick={() => downloadDoc(doc)}>
                {t('open_pdf')}
              </Button>
            </div>
          )}
          <PdfFileModalViewer url={doc?.file} />
        </div>
      </Modal>
    </StepWrapper>
  )
}

export default SignDoc
