import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Outlet, useNavigate } from 'react-router-dom'
import { isAuthenticatedSelector } from 'redux/store/slices/application-slice'
import { stepSelector } from 'redux/store/slices/step-slice'

function ProtectedRoute() {
  const isAuthenticated = useSelector(isAuthenticatedSelector)
  const step = useSelector(stepSelector)
  const navigate = useNavigate()
  console.log('step', step)
  console.log('is aauth', isAuthenticated)

  if (!isAuthenticated || !step) return <Navigate to={'/'} />
  return <Outlet />
}

export default ProtectedRoute
