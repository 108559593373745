import 'twin.macro'

import React, { FC } from 'react'
import tw, { TwStyle } from 'twin.macro'
type TitleProps = {
  children: React.ReactNode
  variant?: 'bold' | 'regular'
  twStyle?: TwStyle
}
const textVariants = {
  bold: tw`font-bold`,
  regular: tw`font-normal`
} as { bold: TwStyle; regular: TwStyle }

const styles = {
  text: ({ variant = 'regular', twStyle }: { variant?: 'bold' | 'regular'; twStyle?: TwStyle }) => [
    tw`lg:text-[22px] md:text-[20px] text-s16`,
    textVariants[variant],
    twStyle
  ]
}

export const Title: FC<TitleProps> = ({ children, variant, twStyle }) => {
  return <h2 css={styles.text({ variant, twStyle })}>{children}</h2>
}
export default Title
