import 'twin.macro'

import BigExclamationIcon from 'assets/icons/BigExclamationIcon'
import BodyText from 'components/UI/body-text/body-text'
import Title from 'components/UI/title/title'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { errorSelector } from 'redux/store/slices/error-slice'

const ErrorPage = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const error = useSelector(errorSelector)
  return (
    <div tw='w-screen h-[70vh] flex flex-col justify-center items-center p-5 -translate-y-20 text-center'>
      <div tw='w-[144px] h-[144px] flex items-center justify-center rounded-full mx-auto bg-yellow-alpha20 mt-[48px] mb-5'>
        <BigExclamationIcon />
      </div>
      <Title variant='bold'>{error.description}</Title>
    </div>
  )
}

export default ErrorPage
