import tw from 'twin.macro'

export const selectStyles = {
  control: (_: any, props: any) => {
    const isError = props.selectProps.isError
      ? Object.keys(props.selectProps.isError).length !== 0
      : !!props.selectProps.isError
    return {
      ...tw`flex items-center rounded-xl bg-input outline-none border border-transparent cursor-pointer shadow-none`,
      ...(isError && tw`border border-input-invalid`)
    }
  },
  singleValue: (provided: any) => ({
    ...provided,
    ...tw`text-secondary text-[14px] leading-[18px] tracking-[-0.15px] font-semibold`
  }),
  indicatorSeparator: (provided: any) => ({ ...provided, ...tw`hidden` }),
  valueContainer: (provided: any) => ({ ...provided, ...tw`text-secondary px-5 py-[14px] leading-[18px]` }),
  dropdownIndicator: (provided: any) => ({ ...provided, ...tw`p-0 pr-6 text-secondary` }),
  input: (provided: any) => ({
    ...provided,
    ...tw`text-secondary py-0 my-0 text-[14px] leading-[18px] tracking-[-0.15px]`
  }),
  placeholder: (provided: any) => ({
    ...provided,
    ...tw`text-[14px] leading-[18px] tracking-[-0.15px] font-normal text-dark-grey`
  }),
  menu: (provided: any) => ({
    ...provided,
    ...tw`bg-secondary p-0 shadow-none top-[unset] my-1 border rounded-[14px] border-grey overflow-hidden`
  }),
  menuList: (provided: any) => ({
    ...provided,
    ...tw`m-0 p-0 border-none`
  }),
  option: (_: any, state: any) => {
    return {
      ...tw`px-5 py-[15px] text-[14px]  [&:not(:first-of-type)]:border-t-[1px] leading-[18px] tracking-[-0.15px] font-bold text-secondary dark:text-white active:bg-none cursor-pointer hover:bg-primary dark:hover:bg-primary`,
      backgroundColor: state.isSelected
        ? state.selectProps.isDark
          ? '#72BF44'
          : 'var(--green-light)'
        : 'var(--green-white)'
    }
  }
}
