import 'twin.macro'

import BigCheckIcon from 'assets/icons/BigCheckIcon'
import { StepWrapper } from 'components/UI/step-wrapper/step-wrapper'
import Title from 'components/UI/title/title'
import React from 'react'
import { useTranslation } from 'react-i18next'
import tw from 'twin.macro'
function Success() {
  const { t } = useTranslation()
  return (
    <StepWrapper twStyle={tw`text-center`}>
      <div tw='w-[144px] h-[144px] flex items-center justify-center rounded-full mx-auto bg-green-alpha sm:my-[48px] my-[30px]'>
        <BigCheckIcon />
      </div>
      <Title variant='bold'>{t('success:success')}</Title>
    </StepWrapper>
  )
}

export default Success
