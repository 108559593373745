import { Button } from 'components/UI/button/button'
import OtpInput from 'components/UI/otp-input.tsx/otp-input'
import { Fragment, ReactNode } from 'react'
import { Control, FieldValues, Path } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import tw, { TwStyle } from 'twin.macro'

import CountdownTimer from './timer'

type OtpVerificationVariants = 'phone-otp' | 'dd-otp'

type OtpVerificationProps<T extends FieldValues> = {
  variant?: OtpVerificationVariants
  phone?: string
  control: Control<T>
  isOtpValid: boolean
  name: Path<T>
  errorMsg?: string
  disabled?: boolean
  otpLength?: number
  initMinutes?: number
  initSeconds?: number
  showOtp?: boolean
  onOtpVerify?: (value: string | undefined) => void
  onChangePhone?: () => void
  onOtpTimerReset?: () => void
  footerContent?: ReactNode
  twStyle?: TwStyle
}

/* 
  TODO:Убрать проп variant и оставить только ввод OTP
  Вынести показ текста в разные компоненты так как щас невозможно извне менять содержимое этого компонента
*/
const OtpVerify = <T extends FieldValues>({
  variant = 'phone-otp',
  phone,
  control,
  name,
  errorMsg,
  disabled = false,
  otpLength = 4,
  initMinutes = 0,
  initSeconds = 59,
  showOtp = true,
  onChangePhone,
  onOtpVerify,
  onOtpTimerReset,
  footerContent,
  twStyle,
  isOtpValid
}: OtpVerificationProps<T>) => {
  const handleOtpVerify = (value: string | undefined) => {
    onOtpVerify?.(value)
  }

  const { t } = useTranslation('otp')

  return (
    <div tw='text-center flex flex-col items-center gap-5'>
      {showOtp && (
        <Fragment>
          <OtpInput
            label={t('label')}
            numInputs={otpLength}
            errorMsg={errorMsg}
            control={control}
            name={name}
            shouldAutoFocus
            disabled={disabled}
            onVerify={handleOtpVerify}
          />
          <Button variant='secondary' disabled={!isOtpValid}>
            {t('continue')}
          </Button>
          <CountdownTimer initialMinutes={initMinutes} initialSeconds={initSeconds} onResetTimer={onOtpTimerReset} />
        </Fragment>
      )}
      {footerContent}
    </div>
  )
}

export default OtpVerify
