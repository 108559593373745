import { ThemeContext, ThemeProviderContext } from 'contexts/theme-context'
import { ReactNode, useContext, useId } from 'react'
import { Control, FieldValues, Path, useController, UseControllerProps } from 'react-hook-form'
import RSelect, { GroupBase, OnChangeValue, Props } from 'react-select'
import tw, { TwStyle } from 'twin.macro'

import SubBody from '../sub-body/sub-body'
import { selectStyles } from './styles'

type SelectProps<
  T extends FieldValues,
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
> = {
  control: Control<T>
  name: Path<T>
  label?: ReactNode
  twStyle?: TwStyle
  isDark?: boolean
  onChange?: (value: OnChangeValue<Option, IsMulti>) => void
} & Omit<Props<Option, IsMulti, Group>, 'name' | 'isDark' | 'onChange'> &
  UseControllerProps<T>

const Select = <
  T extends FieldValues,
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  control,
  name,
  label,
  isDisabled,
  isSearchable = false,
  styles,
  rules,
  twStyle,
  onChange,
  required,
  ...selectProps
}: SelectProps<T, Option, IsMulti, Group>) => {
  const selectId = useId()

  const {
    field: { ref, onBlur, onChange: onFormChange, value, name: fieldName }
  } = useController({ name, control, rules, shouldUnregister: false })

  const themeContext = useContext(ThemeContext)
  const { theme } = themeContext as ThemeProviderContext

  return (
    <span
      css={[tw`flex flex-col space-y-1 mt-1 min-w-0 max-w-full w-full relative`, isDisabled && tw`opacity-50`, twStyle]}
    >
      {label && (
        <label htmlFor={selectId} tw='ml-s13  text-dark-grey'>
          <SubBody>
            {label} {required && <span tw='text-error'>*</span>}
          </SubBody>
        </label>
      )}
      <RSelect
        inputId={selectId}
        ref={ref}
        name={fieldName}
        value={value}
        styles={styles || selectStyles}
        isSearchable={isSearchable}
        isDisabled={isDisabled}
        onBlur={onBlur}
        isDark={theme === 'dark'}
        onChange={value => {
          onFormChange(value)
          onChange?.(value)
        }}
        {...selectProps}
      />
    </span>
  )
}

export default Select
