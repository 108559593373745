import biometryEn from 'i18n/locales/en/biometry.json'
import otpEn from 'i18n/locales/en/otp.json'
import signdocEn from 'i18n/locales/en/signdoc.json'
import stepsEn from 'i18n/locales/en/steps.json'
import successEn from 'i18n/locales/en/success.json'
import biometryKk from 'i18n/locales/kk/biometry.json'
import otpKk from 'i18n/locales/kk/otp.json'
import signdocKk from 'i18n/locales/kk/signdoc.json'
import stepsKk from 'i18n/locales/kk/steps.json'
import successKk from 'i18n/locales/kk/success.json'
import addressRu from 'i18n/locales/ru/address.json'
import biometryRu from 'i18n/locales/ru/biometry.json'
import otpRu from 'i18n/locales/ru/otp.json'
import signdocRu from 'i18n/locales/ru/signdoc.json'
import stepsRu from 'i18n/locales/ru/steps.json'
import successRu from 'i18n/locales/ru/success.json'

export const ruTranslations = {
  biometry: biometryRu,
  signdoc: signdocRu,
  steps: stepsRu,
  otp: otpRu,
  success: successRu,
  address: addressRu
}

export const kkTranslations = {
  biometry: biometryKk,
  signdoc: signdocKk,
  steps: stepsKk,
  otp: otpKk,
  success: successKk
}

export const enTranslations = {
  biometry: biometryEn,
  signdoc: signdocEn,
  steps: stepsEn,
  otp: otpEn,
  success: successEn
}
