import { createSlice } from '@reduxjs/toolkit'

import { RootState } from '..'
type InitialState = {
  step: string | null
}

const initialState = {
  step: null
} as InitialState

const stepSlice = createSlice({
  name: 'stepSlice',
  initialState,
  reducers: {
    setNewStep: (state, { payload }: { payload: string }) => {
      state.step = payload
    }
  }
})

export const stepSelector = (state: RootState) => state.step.step

export const { setNewStep } = stepSlice.actions
export default stepSlice.reducer
