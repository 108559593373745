import * as React from 'react'

function FFLogo() {
  return (
    <svg width='126' height='56' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M25.9773 43C25.9773 43 13 39.1812 13 20.2492V13H38.9547V20.2492C38.9547 38.4693 25.9773 43 25.9773 43Z'
        fill='#50AE3D'
      />
      <path
        d='M33.4855 20.3138H23.0325V26.3332H33.4855V28.1455C33.4855 31.1876 32.6118 33.6471 28.0163 33.6471H23.0325V41.5759C24.7477 42.6439 25.9775 42.9998 25.9775 42.9998C25.9775 42.9998 38.9548 38.4691 38.9548 20.249V14.7798C38.9548 17.8219 37.6279 20.249 33.4855 20.3138Z'
        fill='#0E5430'
      />
      <path
        d='M46.1716 30.8315H48.8577C50.314 30.8315 51.3496 31.4788 51.3496 32.7409C51.3496 33.3558 51.0584 33.9707 50.5082 34.2943V34.3267C51.3496 34.5856 51.6409 35.3299 51.6409 35.9772C51.6409 37.5629 50.314 38.1455 48.8577 38.1455H46.1716V30.8315ZM48.8901 33.6471C49.3431 33.6471 49.5373 33.3235 49.5373 32.9675C49.5373 32.6438 49.3432 32.3526 48.8577 32.3526H47.9839V33.6471H48.8901ZM49.0195 36.5921C49.5373 36.5921 49.7962 36.2361 49.7962 35.8154C49.7962 35.3946 49.5373 35.071 49.0195 35.071H47.9516V36.5921H49.0195Z'
        fill='currentColor'
      />
      <path
        d='M54.3917 30.8315H56.2687L58.7606 38.1131H56.916L56.4629 36.5921H54.1975L53.7444 38.1131H51.8998L54.3917 30.8315ZM56.0422 35.1681L55.6215 33.7442C55.492 33.2911 55.3302 32.482 55.3302 32.482H55.2978C55.2978 32.482 55.136 33.2911 55.0066 33.7442L54.5859 35.1681H56.0422Z'
        fill='currentColor'
      />
      <path
        d='M59.4726 30.8315H61.2849L63.2914 34.2296C63.5826 34.715 63.9063 35.4594 63.9063 35.4594H63.9386C63.9386 35.4594 63.8415 34.6827 63.8415 34.2296V30.8315H65.6215V38.1131H63.8415L61.8027 34.715C61.5114 34.2296 61.1878 33.4853 61.1878 33.4853H61.1554C61.1554 33.4853 61.2525 34.262 61.2525 34.715V38.1131H59.4726V30.8315Z'
        fill='currentColor'
      />
      <path
        d='M68.9548 30.8315V33.6471H69.6991L71.2202 30.8315H73.1296L71.1554 34.3267V34.359L73.2266 38.1455H71.2202L69.6668 35.2005H68.9224V38.1455H67.1425V30.8315H68.9548Z'
        fill='currentColor'
      />
      <path
        d='M46.1716 27.5307V17.3042H52.4499V18.0485C52.4499 18.8252 51.8351 19.4401 51.0584 19.4401H48.6635V21.576H51.7703V22.288C51.7703 23.0647 51.1231 23.712 50.3464 23.712H48.6635V27.4984H46.1716V27.5307Z'
        fill='currentColor'
      />
      <path
        d='M56.3656 21.932H57.4659C57.8866 21.932 58.2102 21.8349 58.4368 21.6084C58.6633 21.3819 58.7928 21.0906 58.7928 20.6699C58.7928 20.1197 58.5986 19.7314 58.1779 19.5696C57.9513 19.4725 57.6277 19.4401 57.2394 19.4401H56.3979V21.932H56.3656ZM53.8413 17.3042H57.4012C58.3073 17.3042 58.9546 17.4013 59.3753 17.5631C59.9578 17.7896 60.4432 18.1456 60.7669 18.6634C61.0905 19.1812 61.2523 19.7961 61.2523 20.5404C61.2523 21.123 61.1229 21.6731 60.864 22.1909C60.6051 22.7087 60.2167 23.0971 59.7313 23.356V23.3883C59.8284 23.5178 59.9578 23.712 60.1196 24.0032L62.0937 27.5955H59.3429L57.5306 24.1327H56.3656V27.5955H53.8413V17.3042Z'
        fill='currentColor'
      />
      <path
        d='M63.2588 27.5307V17.3042H69.6989V19.4401H65.7831V21.3171H68.8899V23.4531H65.7831V25.3948H69.8931V27.5307H63.2588Z'
        fill='currentColor'
      />
      <path
        d='M71.479 27.5307V17.3042H77.8868V19.4401H74.0033V21.3171H77.1101V23.4531H74.0033V25.3948H78.1133V27.5307H71.479Z'
        fill='currentColor'
      />
      <path
        d='M82.1909 25.3948H83.2265C84.1003 25.3948 84.7799 25.1359 85.2653 24.6181C85.7508 24.1003 86.0097 23.356 86.0097 22.3851C86.0097 21.4466 85.7508 20.7023 85.2653 20.1845C84.7799 19.6667 84.1003 19.4401 83.2265 19.4401H82.1909V25.3948ZM79.699 27.5307V17.3042H83.3236C84.9417 17.3042 86.2362 17.7573 87.1747 18.6634C88.1132 19.5696 88.5986 20.7993 88.5986 22.4175C88.5986 24.0356 88.1132 25.2654 87.1747 26.1715C86.2362 27.0777 84.9417 27.5307 83.3236 27.5307H79.699Z'
        fill='currentColor'
      />
      <path
        d='M92.288 22.3529C92.288 23.2267 92.5469 23.9711 93.0647 24.5536C93.5825 25.1361 94.2621 25.4274 95.0388 25.4274C95.8478 25.4274 96.4951 25.1361 97.0129 24.5536C97.5307 23.9711 97.7896 23.2267 97.7896 22.3529C97.7896 21.5115 97.5307 20.7995 97.0129 20.2494C96.4951 19.6992 95.8478 19.408 95.0388 19.408C94.2297 19.408 93.5825 19.6992 93.0647 20.2494C92.5469 20.7995 92.288 21.5115 92.288 22.3529ZM89.699 22.3529C89.699 20.8643 90.1844 19.6345 91.1876 18.6313C92.1909 17.628 93.4854 17.1426 95.0388 17.1426C96.5922 17.1426 97.8867 17.628 98.8899 18.6313C99.8932 19.6345 100.379 20.8643 100.379 22.3529C100.379 23.874 99.8932 25.1685 98.8899 26.1717C97.8867 27.2073 96.5922 27.6927 95.0388 27.6927C93.4854 27.6927 92.1909 27.1749 91.1876 26.1717C90.1844 25.1361 89.699 23.874 89.699 22.3529Z'
        fill='currentColor'
      />
      <path
        d='M101.576 27.5307L102.417 17.3042H105.136L106.624 21.6408L107.142 23.3236H107.175C107.369 22.644 107.531 22.0615 107.692 21.6408L109.181 17.3042H111.9L112.741 27.5307H110.249L109.925 22.9353C109.893 22.7087 109.893 22.4498 109.893 22.1586C109.893 21.8673 109.893 21.6408 109.893 21.479V21.2201H109.861C109.634 21.932 109.44 22.5146 109.278 22.9353L108.21 25.9126H106.074L105.006 22.9353L104.424 21.2201H104.391C104.424 21.8349 104.424 22.4175 104.391 22.9353L104.068 27.5307H101.576Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default FFLogo
