import { RetryOptions } from '@reduxjs/toolkit/dist/query/retry'
import {
  BaseQueryApi,
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
  FetchBaseQueryMeta
} from '@reduxjs/toolkit/query'
import { createApi } from '@reduxjs/toolkit/query/react'
import { RootState } from 'redux/store'

export const APIUrl = import.meta.env.NR_API_URL

const baseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, RetryOptions, FetchBaseQueryMeta> =
  fetchBaseQuery({
    baseUrl: APIUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).application.access_token
      const language = (getState() as RootState).application.lang
      if (token) headers.set('Authorization', `Bearer ${token}`)
      headers.set('Accept', 'application/json')
      headers.set('language', `${language}`)

      return headers
    }
  })

export const api = createApi({
  reducerPath: 'api',
  baseQuery: baseQuery,
  endpoints: () => ({})
})
