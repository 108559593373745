import 'twin.macro'

import BigCheckIcon from 'assets/icons/BigCheckIcon'
import { StepWrapper } from 'components/UI/step-wrapper/step-wrapper'
import Title from 'components/UI/title/title'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import tw from 'twin.macro'
function ForensicsSuccess() {
  const { t } = useTranslation('success')
  return (
    <StepWrapper twStyle={tw`text-center`}>
      <div tw='w-[144px] h-[144px] flex items-center justify-center rounded-full mx-auto bg-green-alpha sm:my-[48px] my-[30px]'>
        <BigCheckIcon />
      </div>
      <Title twStyle={tw`text-center`} variant='bold'>
        <Trans components={{ br: <br /> }}>{t('forensics_success')}</Trans>
      </Title>
    </StepWrapper>
  )
}

export default ForensicsSuccess
