import 'twin.macro'
import 'globals.css'

import { CheckIcon } from 'assets/icons/CheckIcon'
import i18n from 'i18n/config'
import * as R from 'ramda'
import React, { useEffect, useMemo, useState } from 'react'
import { Step, Stepper as ReactStepper } from 'react-form-stepper'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { nr_steps } from 'utils/nr-steps'

type NrStepType = {
  label: string
  path: number
  substeps: string[]
  completed: boolean
}

const connectorStyleConfig = {
  activeColor: '#4FB84E',
  completedColor: '#4FB84E',
  disabledColor: '',
  size: 3,
  style: 'bg-black'
}

const styleConfig = {
  activeBgColor: '#4f9d3a',
  completedBgColor: '#4f9d3a',
  activeTextColor: 'white', // Add these properties
  completedTextColor: 'your-color-here',
  inactiveBgColor: 'rgb(224, 224, 224)',
  inactiveTextColor: 'white',
  size: '30px',
  circleFontSize: '',
  labelFontSize: '',
  borderRadius: '20px',
  fontWeight: ''
}

const urlToArray = R.split('/')

const checkActiveStep = (
  pathname: string,
  originalSteps: { path: string; title: string }[],
  data:
    | {
        label: string
        path: number
        substeps: string[]
        completed: boolean
      }[]
) => {
  const step: string = R.compose(R.last, urlToArray)(pathname) as string
  // if (step === 'success') {
  //   return 8
  // }

  for (let i = 0; i < data.length; i++) {
    const element = originalSteps[i]
    if (element.path.includes(step)) {
      return i + 1
    }
  }
}

const checkCompleteStatus = R.curry(
  (active: number, originalSteps: { path: string; title: string }[], data: NrStepType[]) => {
    console.log('data', data)

    return data.map((step, id) => {
      if (active === 8) {
        return { ...step, completed: true }
      }
      if (active - 1 > id) {
        return { ...step, completed: true }
      }
      return step
    })
  }
)

const Stepper = () => {
  const { t } = useTranslation()
  const [allSteps, setAllSteps] = useState<NrStepType[]>([])
  const [activeStep, setActiveStep] = useState<number | undefined>(1)
  const url = useLocation()

  useEffect(() => {
    setActiveStep(checkActiveStep(url.pathname, nr_steps, allSteps))
  }, [url, t])

  useEffect(() => {
    setAllSteps(checkCompleteStatus(activeStep, nr_steps, allSteps))
  }, [activeStep])

  useEffect(() => {
    const steps = nr_steps.map((step, id) => {
      return {
        label: t(`steps:${step.path.replaceAll('/', '')}`),
        path: id + 1,
        substeps: [step.path],
        completed: false
      }
    })

    setAllSteps(steps)
    setActiveStep(checkActiveStep(url.pathname, nr_steps, steps))
  }, [url.pathname, t])

  return (
    <>
      <div tw='bg-secondary sm:rounded-s20 sm:shadow-content'>
        <ReactStepper
          activeStep={activeStep && activeStep - 1}
          connectorStateColors
          styleConfig={styleConfig}
          connectorStyleConfig={connectorStyleConfig}
          hideConnectors={false}
        >
          {allSteps.map((step, index) => {
            return (
              <Step key={index} label={activeStep === +step.path ? step.label : ''} className='step-style'>
                {step?.completed ? <CheckIcon /> : <span>{step.path}</span>}
              </Step>
            )
          })}
        </ReactStepper>
      </div>
    </>
  )
}

export { Stepper }

// const Step = ({ data }) => {
//   return (
//     <div tw='flex flex-col items-center gap-1'>
//       <div tw='flex items-center justify-center w-[24px] h-[24px] bg-primary rounded-full'>
//         <SubBody text={data.path} twStyle={tw`text-tertiary`} />
//       </div>
//       <SubBody text={data.title} twStyle={tw`text-tertiary`} />
//     </div>
//   );
// };
