import { api } from '.'

const biometryApi = api.injectEndpoints({
  endpoints: build => ({
    getSumsubToken: build.mutation({
      query: ({ application_id }: { application_id: string }) => ({
        url: '/biometry/sumsub/generate-token',
        method: 'POST',
        body: { application_id }
      })
    }),
    setBiometryForensics: build.mutation({
      query: ({ method }: { method: 'LANDING' }) => ({
        url: '/biometry/forensics/results/liveness/method',
        method: 'POST',
        body: { method }
      })
    }),
    processForensicsResult: build.query({
      query: ({ application_id }) => ({
        url: `biometry/forensics/results/liveness/${application_id}`,
        method: 'GET'
      })
    })
  })
})

export const { useGetSumsubTokenMutation, useSetBiometryForensicsMutation, useProcessForensicsResultQuery } =
  biometryApi
