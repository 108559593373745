import { DefaultTFuncReturn } from 'i18next'
import { ChangeEvent, InputHTMLAttributes, ReactNode, useId } from 'react'
import { Control, FieldValues, Path, useController } from 'react-hook-form'
import tw, { TwStyle } from 'twin.macro'

import Caption from '../caption'
import SubBody from '../sub-body/sub-body'

type InputProps<T extends FieldValues> = {
  label?: string | null | DefaultTFuncReturn
  type?: string
  disabled?: boolean
  placeholder?: string
  subText?: ReactNode
  twStyle?: TwStyle
  inputStyles?: TwStyle
  errorStyle?: TwStyle
  icon?: ReactNode
  affix?: ReactNode
  control: Control<T>
  name: Path<T>
  rules?: object
} & InputHTMLAttributes<HTMLInputElement>

const Input = <T extends object>({
  label,
  type = 'text',
  disabled = false,
  placeholder,
  subText,
  twStyle,
  inputStyles,
  errorStyle,
  icon,
  affix,
  control,
  name,
  rules,
  required,
  ...otherInputProps
}: InputProps<T>) => {
  const id = useId()
  const {
    field: { value, onChange, name: fieldName },
    fieldState: { error }
  } = useController({ name, control, rules, shouldUnregister: false })

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value)
  }

  return (
    <span css={[tw`flex flex-col gap-y-1 mt-1 min-w-0 max-w-full w-full relative`, disabled && tw`opacity-50`]}>
      {label && (
        <label htmlFor={id} tw='ml-s13 text-secondary'>
          <SubBody>
            {label} {required && <span tw='text-error'>*</span>}
          </SubBody>
        </label>
      )}
      <span tw='relative' css={[twStyle]}>
        <input
          id={id}
          name={fieldName}
          value={value}
          autoComplete='off'
          type={type}
          disabled={disabled}
          placeholder={placeholder}
          onChange={handleInputChange}
          css={[
            tw`
              text-sm leading-[18px] tracking-[-0.15px] font-bold min-h-[48px] max-h-[48px] outline-none
              px-5 py-[15px] rounded-[14px] border border-transparent focus-within:border-input-active transition-colors
              bg-input placeholder:font-normal w-full`,
            error && tw`border-input-invalid focus-within:border-input-invalid`,
            icon && tw`pl-8`,
            inputStyles
          ]}
          {...otherInputProps}
        />
        {icon && <span tw='absolute left-2 top-1/2 -translate-y-1/2'>{icon}</span>}
        {affix && <span tw='absolute text-secondary right-5 inset-y-1/4'>{affix}</span>}
      </span>

      <span tw='ml-s13 leading-3 bottom-4 text-left h-[10px]' css={[errorStyle]}>
        {(subText || error) && (
          <Caption twStyle={error ? tw`text-error` : tw`text-secondary`}>{subText || error?.message}</Caption>
        )}
      </span>
    </span>
  )
}

export default Input
