import { api } from '.'

const addressApi = api.injectEndpoints({
  endpoints: build => ({
    getUserInfo: build.query({
      query: () => ({
        url: `customers/landing/me/`,
        method: 'GET'
      })
    }),
    sendAddresses: build.mutation({
      query: ({ application_id, taxpayer_number, registration, residence }) => ({
        url: `customers/landing/addresses/`,
        method: 'POST',
        headers: { language: 'kk' },
        body: { application_id, taxpayer_number, registration, residence }
      })
    })
  })
})

export const { useGetUserInfoQuery, useSendAddressesMutation } = addressApi
