import CloseIcon from 'assets/icons/CloseIcon'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { AnimatePresence, motion } from 'framer-motion'
import { MouseEvent, ReactNode, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import tw, { TwStyle } from 'twin.macro'

import BodyText from '../body-text/body-text'
import { Button } from '../button/button'
import Title from '../title/title'

type ModalProps = {
  open: boolean
  children: ReactNode
  title?: ReactNode
  outsideClose?: boolean
  twStyle?: TwStyle
  headerStyle?: TwStyle
  wrapperStyle?: TwStyle
  backdropTwStyle?: TwStyle
  onClose?: () => void
  withBackdrop?: boolean
  divider?: boolean
}

const Modal = ({
  open = false,
  children,
  title,
  withBackdrop = true,
  twStyle,
  divider = false,
  headerStyle,
  wrapperStyle,
  onClose,
  outsideClose = true
}: ModalProps) => {
  const { t } = useTranslation()
  const handleClose = () => {
    onClose?.()
  }

  const handleOutside = (event: MouseEvent) => {
    if (outsideClose) {
      event.preventDefault()
      onClose?.()
    }
  }

  return (
    <AnimatePresence>
      {open && !withBackdrop && (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
        <div
          css={[
            tw`min-h-screen overflow-y-scroll w-full bg-modal flex fixed top-0 left-0 bottom-0 right-0 z-50 sm:p-5 !mt-[-5px] text-center`,
            wrapperStyle
          ]}
          onClick={handleOutside}
        >
          <motion.div
            css={[twStyle]}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50 }}
            transition={{ duration: '0.5' }}
            onClick={e => {
              e.stopPropagation()
              e.preventDefault()
            }}
            tw='p-8 m-auto bg-primary'
          >
            <div tw='flex justify-between' css={[headerStyle]}>
              <Title variant='bold'>{title}</Title>
              <div tw='self-center !rounded-xl'>
                <CloseIcon onClick={handleClose} tw='cursor-pointer flex-shrink-0 w-4 h-4' />
              </div>
            </div>
            <div>{children}</div>
          </motion.div>
        </div>
      )}

      {open && withBackdrop && (
        <div tw='min-h-screen fixed top-0 bottom-0 left-0 right-0 overflow-auto z-50 rounded-t-2xl backdrop-blur-sm flex flex-col-reverse'>
          <motion.div
            initial={{ height: 0, position: 'relative' }}
            animate={{ height: 'auto' }}
            exit={{ height: 0 }}
            transition={{ duration: 0.5 }}
            tw='overflow-hidden'
          >
            <div tw='bg-primary rounded-t-2xl relative w-full h-full mb-[15vh]' css={[twStyle]}>
              <div tw='flex justify-between p-5 bg-secondary' css={[headerStyle]}>
                <BodyText variant='bold'>{title}</BodyText>
                <Button variant='link' onClick={handleClose}>
                  <span tw='inline-block text-sm leading-5 font-bold text-green'>{t('biometry:close')}</span>
                </Button>
              </div>
              {children}
            </div>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  )
}

export { Modal }
