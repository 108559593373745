import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query/react'
import { api } from 'redux/api'
import { FLUSH, PERSIST, persistReducer, persistStore, PURGE, REGISTER } from 'redux-persist'
import sessionStorage from 'redux-persist/es/storage/session'
import storage from 'redux-persist/lib/storage'

import applicationSlice from './slices/application-slice'
import errorSlice from './slices/error-slice'
import stepSlice from './slices/step-slice'

const persistConfig = {
  key: 'root',
  storage: sessionStorage,
  version: 1,
  blacklist: ['api']
}

const rootReducer = combineReducers({
  application: applicationSlice,
  step: stepSlice,
  error: errorSlice,
  [api.reducerPath]: api.reducer
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [REGISTER, PERSIST, PURGE, FLUSH]
      }
    }).concat(api.middleware),
  devTools: import.meta.env.DEV
})

setupListeners(store.dispatch)

export const persistor = persistStore(store)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
