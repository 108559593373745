import React, { FC } from 'react'
import tw, { TwStyle } from 'twin.macro'

type StepWrapperProps = {
  children: React.ReactNode
  twStyle?: TwStyle
}
const styles = {
  container: ({ twStyle }: { twStyle?: TwStyle }) => [
    tw`bg-secondary sm:rounded-s20 sm:shadow-content mt-[3px] sm:mt-[40px] py-[42px] px-[17px] flex flex-col gap-2`,
    twStyle
  ]
}

const StepWrapper: FC<StepWrapperProps> = ({ children, twStyle }) => {
  return <div css={styles.container({ twStyle })}>{children}</div>
}

export { StepWrapper }
