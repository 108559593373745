import 'globals.css'

import WebSocketComponent from 'components/views/websocket-component'
import StepLayout from 'layout/step-layout'
import Steps from 'pages/Steps'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter, useLocation } from 'react-router-dom'
import { persistor } from 'redux/store'
import { authDataSelector } from 'redux/store/slices/application-slice'
import { PURGE } from 'redux-persist'
function App() {
  const { access_token, application_id } = useSelector(authDataSelector)
  // const { pathname } = useLocation()
  // const dispatch = useDispatch()
  // useEffect(() => {
  //   if (pathname === '/') {
  //     dispatch({ type: PURGE, result: () => null })
  //     console.log('clear')
  //   }
  // }, [pathname])

  return (
    <BrowserRouter>
      {access_token && application_id && (
        <WebSocketComponent access_token={access_token} application_id={application_id} />
      )}

      <Steps />
    </BrowserRouter>
  )
}

export default App
