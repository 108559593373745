import { Button } from 'components/UI/button/button'
import SubBody from 'components/UI/sub-body/sub-body'
import SubTitle from 'components/UI/sub-title/sub-title'
import React, { Fragment, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import tw, { TwStyle } from 'twin.macro'
import { pathnames } from 'utils/nr-steps'

function SumsubBackdropContent({ twStyle, handleContinue }: { twStyle?: TwStyle; handleContinue: () => void }) {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const rules = useMemo(
    () =>
      pathname === pathnames.sumsub
        ? [t('biometry:rules.good_light'), t('biometry:rules.passport'), t('biometry:rules.face_fit')]
        : [t('biometry:rules.good_light'), t('biometry:rules.face_fit')],
    [t]
  )
  return (
    <>
      <div tw='mt-[10px] text-left flex flex-col px-2 gap-3 bg-secondary p-6 rounded-md' css={[twStyle]}>
        <SubTitle variant='bold'>Убедитесь, что:</SubTitle>
        {rules?.map((item, idx) => (
          <SubBody twStyle={tw`leading-5`} key={idx}>
            {idx + 1}. {item}
          </SubBody>
        ))}
      </div>
      <div tw='sm:px-0 px-5'>
        <Button variant={'secondary'} onClick={handleContinue} twStyle={tw`mt-[20px] mb-[10px] `}>
          {t('biometry:continue')}
        </Button>
      </div>
    </>
  )
}

export default SumsubBackdropContent
