import { motion } from 'framer-motion'
import React, { FC } from 'react'
import { Oval } from 'react-loader-spinner'
import tw, { TwStyle } from 'twin.macro'

import BodyText from '../body-text/body-text'

// ButtonVariant = 'primary' | 'ghost' | 'secondary' | 'text' | 'link' | 'download' ;

type ButtonProps = {
  variant: 'primary' | 'ghost' | 'secondary' | 'text' | 'link' | 'download'
  children: React.ReactNode
  caption?: string
  icon?: JSX.Element
  disabled?: boolean
  onClick?: () => void
  loading?: boolean
  twStyle?: TwStyle
  type?: any
}

type SecondaryButtonProps = {
  variant?: 'primary' | 'ghost' | 'secondary' | 'text' | 'link' | 'download'
  text?: React.ReactNode
  icon?: JSX.Element
  loading?: boolean
}

const buttonVariants = {
  primary: tw`max-w-button bg-button text-white rounded-s30 shadow-button hover:bg-button-hover transition-colors disabled:bg-button`,
  link: tw`text-green p-0 active:text-link-hover hover:text-link-hover disabled:text-green max-w-none w-auto min-w-0`,
  text: tw`text-link rounded-s20 py-4 active:text-link-hover hover:text-link-hover disabled:text-green`,
  ghost: tw`border border-green bg-selector text-green rounded-s20 py-4 hover:border-button-hover active:text-green-middle disabled:border-button disabled:text-green`,
  secondary: tw`bg-button text-white rounded-s20 py-4 shadow-button hover:bg-button-hover transition-colors disabled:bg-button`,
  download: tw`bg-secondary py-3 w-full min-w-0 rounded-2xl hover:border-button leading-s18`,
  shadow: tw`border border-dark text-green rounded-s20 py-4 hover:border-button-hover active:text-green-middle disabled:border-button disabled:text-green`
}

const styles = {
  button: ({
    variant = 'text',
    twStyle,
    loading
  }: {
    variant?: 'primary' | 'ghost' | 'secondary' | 'text' | 'link' | 'download'
    twStyle?: TwStyle
    loading?: boolean
  }) => [
    tw`w-full min-w-button py-5 px-6 disabled:opacity-60 disabled:cursor-not-allowed`,
    buttonVariants[variant],
    twStyle && twStyle,
    variant === 'ghost' && loading && tw`h-[51px] py-0`
  ]
}

const Button: FC<ButtonProps> = ({
  variant,
  children,
  caption = '',
  icon,
  disabled,
  type,
  onClick,
  loading = false,
  twStyle
}) => {
  return (
    <motion.button
      css={styles.button({ variant, twStyle, loading })}
      disabled={loading || disabled}
      whileTap={{ scale: !disabled || !loading ? 0.97 : 1 }}
      type={type}
      onClick={onClick}
    >
      {variant === 'ghost' && <SecondaryButton loading={loading} text={children} icon={icon} />}
      {variant === 'secondary' && <SecondaryButton loading={loading} text={children} icon={icon} />}
      {variant === 'text' && <SecondaryButton loading={loading} text={children} icon={icon} />}
      {variant === 'link' && <SecondaryButton loading={loading} text={children} icon={icon} variant={variant} />}
    </motion.button>
  )
}

export { Button }

const SecondaryButton: FC<SecondaryButtonProps> = ({ icon, text, variant = '', loading }) => {
  return (
    <div
      css={[tw`flex justify-center items-center space-x-3 flex-none relative`, variant === 'link' && tw`justify-start`]}
    >
      <BodyText variant='bold' twStyle={loading ? tw`line-clamp-1 text-s14 -ml-10` : tw`line-clamp-1 text-s14`}>
        {text}
      </BodyText>
      {loading ? (
        <Oval
          height={20}
          width={20}
          color='#fff'
          wrapperStyle={{}}
          wrapperClass=''
          visible={true}
          ariaLabel='oval-loading'
          secondaryColor='#ccc'
          strokeWidth={2}
          strokeWidthSecondary={2}
        />
      ) : (
        icon
      )}
    </div>
  )
}
