import { Button } from 'components/UI/button/button'
import SubBody from 'components/UI/sub-body/sub-body'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import tw from 'twin.macro'

type Timer1Props = {
  initialSeconds: number
  initialMinutes: number
  showText?: boolean
  onResetTimer?: () => void
}

const CountdownTimer = ({ initialSeconds, initialMinutes, showText = true, onResetTimer }: Timer1Props) => {
  const [seconds, setSeconds] = useState(initialSeconds)
  const [minutes, setMinutes] = useState(initialMinutes)

  const { t } = useTranslation('otp')

  const handleResetTimer = () => {
    setMinutes(initialMinutes)
    setSeconds(initialSeconds)
    onResetTimer?.()
  }

  useEffect(() => {
    const myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1)
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval)
        } else {
          setMinutes(minutes - 1)
          setSeconds(59)
        }
      }
    }, 1000)
    return () => {
      clearInterval(myInterval)
    }
  }, [minutes, seconds])

  if (!showText) {
    return <SubBody variant='bold'>{`${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`}</SubBody>
  }

  return (
    <div>
      {minutes === 0 && seconds === 0 ? (
        <Button variant='link' onClick={handleResetTimer}>
          {t('resend')}
        </Button>
      ) : (
        <div>
          <SubBody>{t('resend_after', { time: `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}` })}</SubBody>
          {/* <SubBody variant='bold'>{`${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`}</SubBody> */}
        </div>
      )}
    </div>
  )
}

export default CountdownTimer
