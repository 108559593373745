import { css, Global } from '@emotion/react'
import React from 'react'
import { Fragment } from 'react'
import tw, { GlobalStyles as BaseStyles } from 'twin.macro'

const customStyles = css({
  ':root': {
    '--color-white': '#ffffff',
    '--color-white-alpha87': 'rgba(255, 255, 255, 0.87)',
    '--color-white-alpha06': 'rgba(255, 255, 255, 0.06)',
    '--color-white-alpha25': 'rgba(255, 255, 255, 0.25)',
    '--color-black': '#17191a',
    '--color-black-alpha20a': 'rgba(0, 0, 0, 0.2)',
    '--color-biometry': ' #202020',
    '--color-overlay': 'rgba(0, 0, 0, 0.8)',
    '--color-dark-grey': '#1a1a1a',
    '--color-progress-50': '#e7f0e1',
    '--color-progress-150': '#eafae6',
    '--dark-grey': '#8e8e93',
    '--lighter-grey': '#e8eaeb',
    '--light-grey': '#f5f6f7',
    '--green-primary': '#72bf44',
    '--green-primary-alpha': 'rgba(114, 191, 68, 0.2)',
    '--yellow-alpha20': 'rgba(238, 187, 8, 0.2)',
    '--red-alpha20': 'rgba(251, 85, 85, 0.1)',
    '--green-secondary': '#4f9d3a',
    '--green-dark': '#2d7d2f',
    '--light-red': ' #fb5555',
    '--dark-red': '#c24141',
    '--modal': '#0000009c',
    '--middle-grey': '#8e8e93',
    '--color-grey-50': '#eeeeef',
    '--color-grey-150': '#383838',
    '--color-alpha-orange': 'rgba(255, 164, 29, 0.1)',
    '--color-alpha-red': 'rgba(251, 85, 85, 0.1)',
    '--color-green-gradient': 'linear-gradient(83.37deg, #4fb84e 10.41%, #2a8640 89.59%)'
  },
  '.light': {
    '--bg-primary': 'var(--light-grey)',
    '--bg-secondary': 'var(--color-white)',
    '--bg-secondary-inverted': '#282828',
    '--bg-progress': 'var(--color-progress-50)',
    '--bg-wrapper': 'var(--color-white)',
    '--bg-cardcontainer': 'rgba(191, 191, 191, 0.2)',
    '--bg-extra': 'var(--color-white)',
    '--bg-input': 'var(--light-grey)',
    '--bg-green': 'var(--green-primary)',
    '--bg-green-secondary': 'var(--green-secondary)',
    '--bg-additional': 'rgba(238, 238, 238, 0.94)',
    '--bg-toolbar': 'rgba(238, 238, 238, 0.94)',
    '--bg-selector': 'rgba(79, 157, 58, 0.1)',
    '--text-primary': 'var(--color-black)',
    '--text-primary-inverted': 'var(--color-white-alpha87)',
    '--text-secondary': 'var(--dark-grey)',
    '--text-tertiary': 'var(--dark-grey)',
    '--text-green': 'var(--green-secondary)',
    '--text-green-secondary': 'var(--green-secondary)',
    '--text-green-hover': 'var(--green-dark)',
    '--text-error': 'var(--dark-red)',
    '--border-button': 'var(--lighter-grey)',
    '--border-input-active': 'var(--green-secondary)',
    '--border-input-invalid': 'var(--dark-red)',
    '--border-grey': 'var(--lighter-grey)',
    '--grey-footer': 'var(--color-grey-50)',
    '--divide-grey': 'var(--lighter-grey)',
    '--bg-border': '#e8eaeb'
  },
  '.dark': {
    '--bg-primary': 'var(--color-dark-grey)',
    '--bg-secondary': '#282828',
    '--bg-secondary-inverted': 'var(--color-white)',
    '--bg-progress': 'var(--color-progress-150)',
    '--bg-wrapper': 'var(--color-biometry)',
    '--bg-cardcontainer': 'rgba(255, 255, 255, 0.1)',
    '--bg-extra': 'var(--color-dark-grey)',
    '--bg-input': '#00000033',
    '--bg-green': 'var(--green-dark)',
    '--bg-alpha-orange': 'var(--color-alpha-orange)',
    '--bg-alpha-red': 'var(--color-alpha-red)',
    '--bg-green-secondary': 'var(--green-secondary)',
    '--bg-additional': '#454545',
    '--bg-toolbar': 'rgba(58, 58, 58, 0.94)',
    '--bg-selector': 'rgba(114, 191, 68, 0.1)',
    '--text-primary': 'var(--color-white-alpha87)',
    '--text-primary-inverted': 'var(--color-black)',
    '--text-secondary': '#b7bbbd',
    '--text-tertiary': 'var(--color-white-alpha87)',
    '--text-green': 'var(--green-secondary)',
    '--text-green-secondary': 'var(--green-primary)',
    '--text-green-hover': 'var(--green-secondary)',
    '--text-error': 'var(--light-red)',
    '--border-button': 'var(--middle-grey)',
    '--border-input-active': 'var(--green-primary)',
    '--border-input-invalid': 'var(--light-red)',
    '--border-grey': 'var(--color-white-alpha06)',
    '--grey-footer': 'var(--color-grey-150)',
    '--divide-grey': 'var(--color-white-alpha25)',
    '--bg-border': '#454545'
  },

  body: tw`bg-primary text-primary antialiased transition-all duration-200`,

  '.step-style + .LabelContainer-0-2-8 .Label-0-2-9': tw`text-secondary`,
  '.step-style.active + .LabelContainer-0-2-8 .Label-0-2-9': tw`text-primary`,

  '.pagination': tw`flex items-center justify-center gap-3`,

  '.pagination .selected': tw`bg-green-secondary text-white w-[30px] h-[30px] flex items-center justify-center font-bold rounded-full duration-75`,

  'input, textarea, button, select, a': {
    WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)'
  },

  '::-webkit-scrollbar': tw`w-2 h-2`,

  '::-webkit-scrollbar-track': {
    background: 'var(--bg-primary)'
  },

  '::-webkit-scrollbar-thumb': tw`bg-[#4f9d3a] border-[3px] border-[#4f9d3a] rounded-2xl `
})

const GlobalStyles = () => {
  return (
    <Fragment>
      <BaseStyles />
      <Global styles={customStyles} />
    </Fragment>
  )
}

export default GlobalStyles
