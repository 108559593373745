import SubBody from 'components/UI/sub-body/sub-body'
import { DefaultTFuncReturn } from 'i18next'
import { useEffect } from 'react'
import { Control, FieldValues, Path, useController } from 'react-hook-form'
import OtpInputComponent from 'react-otp-input'
import tw from 'twin.macro'

type OtpInputProps<T extends FieldValues> = {
  control: Control<T>
  name: Path<T>
  label?: DefaultTFuncReturn
  shouldAutoFocus?: boolean
  disabled?: boolean
  rules?: object
  errorMsg?: string
  numInputs?: number
  onVerify?: (value: string | undefined) => void
}

const OtpInput = <T extends FieldValues>({
  control,
  name,
  label,
  shouldAutoFocus,
  disabled,
  rules,
  errorMsg,
  onVerify,
  numInputs = 4
}: OtpInputProps<T>) => {
  const {
    field: { onChange, value },
    fieldState: { error }
  } = useController({ control, name, rules })

  useEffect(() => {
    if (onVerify && value.length === numInputs) {
      onVerify(value)
      const activeElement = document.activeElement as HTMLElement
      activeElement?.blur()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, numInputs])

  return (
    <div css={[tw`flex flex-col space-y-2 min-w-0 max-w-button `, disabled && tw`opacity-50`]}>
      {label && (
        <label htmlFor={name} tw='text-secondary'>
          <SubBody>{label}</SubBody>
        </label>
      )}
      <OtpInputComponent
        shouldAutoFocus={shouldAutoFocus}
        value={value}
        onChange={onChange}
        hasErrored={error && Object.keys(error).length !== 0}
        isInputNum={true}
        isDisabled={disabled}
        numInputs={numInputs}
        inputStyle={tw`border border-transparent outline-none text-primary font-bold bg-input w-[50px] h-[60px] mr-2 rounded-2xl `}
        focusStyle={tw`border-input-active`}
        errorStyle={tw`border-input-invalid focus:border-input-invalid`}
      />
      {(error || errorMsg) && (
        <span tw='ml-s13 leading-3'>
          <SubBody twStyle={tw`text-error relative right-3`}>{errorMsg || error?.message}</SubBody>
        </span>
      )}
    </div>
  )
}

export default OtpInput
