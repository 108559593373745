import 'twin.macro'

import FFLogo from 'assets/icons/FreedomLogo'
import PhoneIcon from 'assets/icons/PhoneIcon'
import WhatsappIcon from 'assets/icons/WhatsApp'
import ContactItem from 'components/UI/contact/contacat-item'
import { MenuToggle } from 'components/UI/menu-toggle/menu-toggle.jsx'
import { motion, useCycle } from 'framer-motion'
import i18n from 'i18n/config'
import React, { useMemo } from 'react'
import tw from 'twin.macro'

import ThemeToggle from './theme-toggle'

const languages = [
  { title: 'Қаз', value: 'kk' },
  { title: 'Рус', value: 'ru' },
  { title: 'Eng', value: 'en' }
]
function Header() {
  const sidebar = useMemo(() => {
    return {
      open: (height = 1000) => ({
        clipPath: `circle(${height * 2 + 200}px at 40px 40px)`,
        transition: {
          type: 'spring',
          stiffness: 20,
          restDelta: 2
        }
      }),
      closed: {
        clipPath: 'circle(1px at 1px 1px)',
        transition: {
          type: 'spring',
          stiffness: 400,
          damping: 40
        }
      }
    }
  }, [])

  const [isOpen, toggleOpen] = useCycle(false, true)

  return (
    <div tw='max-w-app mx-auto flex items-center justify-between py-2 lg:px-0 px-2 bg-secondary relative z-50'>
      <a href='/'>
        <FFLogo />
      </a>
      <div tw='flex gap-2 mr-2 items-center'>
        <ContactItem link='https://wa.me/77760595595' icon={<WhatsappIcon />} />
        <ContactItem link='tel:595' icon={<PhoneIcon />} />
        <ThemeToggle />
      </div>

      {/* <motion.div initial={false} animate={isOpen ? 'open' : 'closed'} custom={'100vh'}>
        <motion.div
          tw='absolute top-0 bottom-0 left-0 h-full flex flex-col py-16 px-5 w-full bg-secondary overflow-hidden z-20   h-[100vh]'
          variants={sidebar}
        >
          <div tw='flex flex-col gap-2 divide-y divide-grey'>
            <div tw='flex gap-2 py-2'></div>
          </div>
        </motion.div>
      </motion.div> */}
    </div>
  )
}

export default Header
