import 'twin.macro'

import React, { FC } from 'react'

interface ContactItemProps {
  link: string
  icon: React.ReactNode
}
const ContactItem: FC<ContactItemProps> = ({ icon, link }) => {
  return (
    <div tw='sm:w-[32px] sm:h-[32px] h-[28px] w-[28px] flex items-center justify-center bg-primary rounded-full'>
      <a href={link}>{icon}</a>
    </div>
  )
}

export default ContactItem
