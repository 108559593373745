import { api } from '.'

const otpApi = api.injectEndpoints({
  endpoints: build => ({
    sendOtp: build.mutation({
      query: ({ otp_type }: { otp_type: string }) => ({
        url: 'customers/otp/common/send/',
        method: 'POST',
        body: { otp_type },
        headers: { language: 'ru' }
      })
    }),
    otpVerify: build.mutation({
      query: ({ otp_type, code }: { otp_type: string; code: string }) => ({
        url: `customers/otp/common/verify/`,
        method: 'POST',
        body: { otp_type, code }
      })
    })
  })
})

export const { useSendOtpMutation, useOtpVerifyMutation } = otpApi
