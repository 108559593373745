import 'react-toastify/dist/ReactToastify.css'
import 'globals.css'
import 'i18n/config'
import { BrowserTracing } from '@sentry/react'
import * as Sentry from '@sentry/react'
import App from 'App'
import { ThemeProvider } from 'contexts/theme-context'
import GlobalStyles from 'GlobalStyles'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { store } from 'redux/store'

const container = document.getElementById('root') as HTMLElement

const root = ReactDOM.createRoot(container)

Sentry.init({
  dsn: 'https://267bc35258683985d1b501d70b99b7c4@sentry-prod.trafficwave.kz/20',
  integrations: integrations => {
    const arr = [...integrations, new BrowserTracing()]
    return arr.filter(integration => integration.name !== 'FunctionToString')
  },
  environment: import.meta.env.MODE
})

root.render(
  <React.StrictMode>
    <ThemeProvider initialTheme='dark'>
      <GlobalStyles />
      <Provider store={store}>
        <App />
      </Provider>
    </ThemeProvider>
  </React.StrictMode>
)
