import SumsubWebSdk from '@sumsub/websdk-react'
import BodyText from 'components/UI/body-text/body-text'
import { Button } from 'components/UI/button/button'
import { Modal } from 'components/UI/modal-window/modal'
import { StepWrapper } from 'components/UI/step-wrapper/step-wrapper'
import Title from 'components/UI/title/title'
import BiometryContent from 'components/views/biometry-content'
import SumsubBackdropContent from 'components/views/sumsub-backdrop-content'
import { ThemeContext } from 'contexts/theme-context'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { BeatLoader } from 'react-spinners'
import { useGetSumsubTokenMutation } from 'redux/api/biometry.api'
import { applicationIdSelector } from 'redux/store/slices/application-slice'
import { setNewStep, stepSelector } from 'redux/store/slices/step-slice'
import tw from 'twin.macro'
import { wsStatuses } from 'utils/ws-statuses'

import samsub_ru_locale from '../../i18n/samsub_ru_locale.json'
import faceDark from './../../assets/images/fr-anim-dark.gif'
import faceLight from './../../assets/images/fr-anim-light.gif'
function SumSub() {
  const [isSumsubTokenReceived, setIsSumsubTokenReceived] = useState<boolean>(false)
  const [isSdkLoaded, setIsSdkLoaded] = useState<boolean>(false)
  const [isDocSubmitted, setIsDocSubmitted] = useState<boolean>(false)
  const [modalOpen, setModalOpen] = useState<boolean>(false)

  const { theme } = useContext(ThemeContext)

  const application_id = useSelector(applicationIdSelector)
  const step = useSelector(stepSelector)

  const { t } = useTranslation('biometry')

  const face = theme === 'dark' ? faceDark : faceLight

  const navigate = useNavigate()
  const config = useMemo(() => {
    return {
      lang: 'ru',
      country: 'rus',
      email: '',
      phone: '',
      i18n: samsub_ru_locale,
      documentDefinitions: {
        country: 'ru',
        idDocType: 'IDENTITY'
      }
    }
  }, [])

  const options = useMemo(() => {
    return { addViewportTag: false, adaptIframeHeight: true }
  }, [])

  const [getToken, { data: sumsubToken }] = useGetSumsubTokenMutation()

  const handleGetToken = () => {
    application_id && getToken({ application_id })
  }

  const accessTokenExpirationHandler = () => {
    handleGetToken()
  }
  const messageHandler = (type: string, payload: any) => {
    console.log('type', type)
    console.log('payload', payload)

    if (type === 'idCheck.onApplicantLoaded') {
      setIsSdkLoaded(true)
    }
    if (type === 'idCheck.onApplicantSubmitted') {
      setIsDocSubmitted(true)
    }
    if (type === 'idCheck.applicantStatus' && payload.reviewStatus === 'pending') {
      setIsDocSubmitted(true)
    }
  }

  const errorHandler = (error: any) => {
    console.error('WebSDK onError', error)
  }

  useEffect(() => {
    if (sumsubToken?.token) {
      setIsSumsubTokenReceived(true)
      setModalOpen(false)
    }
  }, [sumsubToken])

  return (
    <StepWrapper twStyle={tw`text-center`}>
      {step === wsStatuses.bank_checks ? (
        <div tw='w-full flex flex-col items-center'>
          <div tw='w-[144px] h-[144px] flex items-center justify-center rounded-full mx-auto bg-green-alpha sm:my-[48px] my-[30px]'>
            <BeatLoader color='#2d7d2f' size={'20'} />
          </div>
          <BodyText twStyle={tw`text-center`} variant='bold'>
            Ваши документы проверяются,
            <br />
            пожалуйста подождите
          </BodyText>
          {/* <Button variant='text' onClick={() => navigate('/internal-checks')}>
            Skip
          </Button> */}
        </div>
      ) : !isSumsubTokenReceived ? (
        <BiometryContent handleContinue={handleGetToken} />
      ) : (
        <div tw='bg-white rounded-2xl'>
          {!isSdkLoaded && (
            <div tw='w-full min-h-[150px] flex justify-center items-center mt-8'>
              <BodyText>Загрузка видеопроверки...</BodyText>
            </div>
          )}
          <SumsubWebSdk
            accessToken={sumsubToken?.token}
            expirationHandler={accessTokenExpirationHandler}
            config={config}
            options={options}
            onMessage={messageHandler}
            onError={errorHandler}
          />
        </div>
      )}
    </StepWrapper>
  )
}

export default SumSub
