import { yupResolver } from '@hookform/resolvers/yup'
import { Button } from 'components/UI/button/button'
import Caption from 'components/UI/caption'
import Input from 'components/UI/input'
import Select from 'components/UI/select'
import { StepWrapper } from 'components/UI/step-wrapper/step-wrapper'
import Title from 'components/UI/title/title'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useGetUserInfoQuery, useSendAddressesMutation } from 'redux/api/address.api'
import { applicationIdSelector } from 'redux/store/slices/application-slice'
import tw from 'twin.macro'
import * as yup from 'yup'

const country_options = [{ label: 'Казахстан', value: 'KZ' }]
function AddressPage() {
  const { t } = useTranslation('address')
  const application_id = useSelector(applicationIdSelector)
  const { data: userData } = useGetUserInfoQuery({})
  const [sendAddresses] = useSendAddressesMutation()
  const schema = yup.object({
    registration_city: yup
      .string()
      .min(0, `${t('field_is_required')}`)
      .max(70)
      .required(`${t('field_is_required')}`),
    registration_street: yup
      .string()
      .min(0, `${t('field_is_required')}`)
      .max(70)
      .required(`${t('field_is_required')}`),
    registration_building: yup
      .string()
      .min(0, `${t('field_is_required')}`)
      .max(10, 'Поле должно содержать максимум 10 символов')
      .required(`${t('field_is_required')}`),
    registration_flat: yup
      .string()
      .min(0, `${t('field_is_required')}`)
      .max(10, 'Поле должно содержать максимум 10 символов')
      .required(`${t('field_is_required')}`),
    live_city: yup
      .string()
      .min(0, `${t('field_is_required')}`)
      .max(70)
      .required(`${t('field_is_required')}`),
    live_street: yup
      .string()
      .min(0, `${t('field_is_required')}`)
      .max(70)
      .required(`${t('field_is_required')}`),
    live_building: yup
      .string()
      .min(0, `${t('field_is_required')}`)
      .max(10, 'Поле должно содержать максимум 10 символов')
      .required(`${t('field_is_required')}`),
    live_flat: yup
      .string()
      .min(0, `${t('field_is_required')}`)
      .max(10, 'Поле должно содержать максимум 10 символов')
      .required(`${t('field_is_required')}`),
    taxpayer_number: userData?.taxpayer_number_is_required
      ? yup
          .string()
          .min(0, `${t('field_is_required')}`)
          .max(100)
          .test(
            'does-not-contain-invalid-chars',
            "$ % @ * ^ : ; & ? # № “ '  /| - данные символы не допустимы",
            value => !value || !/[$%@*^:;&?#№“'\\|]/.test(value)
          )
          .required(`${t('field_is_required')}`)
      : yup.string().notRequired()
  })

  const {
    control,
    formState: { isValid },
    getValues
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      country_of_citizenship: '',
      registration_city: '',
      registration_street: '',
      registration_building: '',
      registration_flat: '',
      live_country: '',
      live_flat: '',
      live_city: '',
      live_street: '',
      live_building: '',
      taxpayer_number: ''
    },
    resolver: yupResolver(schema)
  })

  const submitForm = () => {
    const data = getValues()
    const requestData = {
      application_id,
      taxpayer_number: data.taxpayer_number,
      registration: {
        region_and_city: data.registration_city,
        street: data.registration_street,
        house: data.registration_building,
        apartment: data.registration_flat
      },
      residence: {
        region_and_city: data.live_city,
        street: data.live_street,
        house: data.live_building,
        apartment: data.live_flat
      }
    }
    sendAddresses(requestData)
  }
  return (
    <StepWrapper>
      <div tw='flex flex-col gap-2'>
        <Title variant='bold' twStyle={tw`text-center`}>
          {t('registration_address')}
        </Title>
        <Select
          required={true}
          control={control}
          value={{ label: userData?.citizenship, value: '' }}
          name='country_of_citizenship'
          label={t('country_of_citizenship')}
          options={[]}
          isDisabled
          getOptionLabel={option => option.label}
          getOptionValue={option => option.value}
        />
        <Input
          required={true}
          control={control}
          name={'registration_city'}
          label={t('city.label')}
          placeholder={`${t('city.placeholder')}`}
        />
        <Input
          required={true}
          control={control}
          name={'registration_street'}
          label={t('street.label')}
          placeholder={`${t('street.placeholder')}`}
        />
        <Input
          required={true}
          control={control}
          name={'registration_building'}
          label={t('building.label')}
          placeholder={`${t('building.placeholder')}`}
        />
        <Input
          required={true}
          control={control}
          name={'registration_flat'}
          label={t('flat.label')}
          placeholder={`${t('flat.placeholder')}`}
        />
      </div>
      <div tw='mt-5 flex flex-col gap-2'>
        <Title variant='bold' twStyle={tw`text-center`}>
          {t('live_address')}
        </Title>
        <Select
          required={true}
          control={control}
          name='live_country'
          label={t('country.label')}
          options={[]}
          isDisabled
          value={country_options[0]}
          getOptionLabel={option => option.label}
          getOptionValue={option => option.value}
        />
        <Input
          required={true}
          control={control}
          name={'live_city'}
          label={t('city.label')}
          placeholder={`${t('city.placeholder')}`}
        />
        <Input
          required={true}
          control={control}
          name={'live_street'}
          label={t('street.label')}
          placeholder={`${t('street.placeholder')}`}
        />
        <Input
          required={true}
          control={control}
          name={'live_building'}
          label={t('building.label')}
          placeholder={`${t('building.placeholder')}`}
        />
        <Input
          required={true}
          control={control}
          name={'live_flat'}
          label={t('flat.label')}
          placeholder={`${t('flat.placeholder')}`}
        />
      </div>
      {userData?.taxpayer_number_is_required && (
        <div tw='mb-5'>
          <Input
            required={true}
            control={control}
            name={'taxpayer_number'}
            label={t('taxpayer_number.label')}
            placeholder={`${t('taxpayer_number.placeholder')}`}
          />
          <Caption twStyle={tw`text-secondary left-2 relative`}>{t('taxpayer_number.tip')} </Caption>
        </div>
      )}

      <Button variant='secondary' disabled={!isValid} onClick={submitForm}>
        {t('button_text')}
      </Button>
    </StepWrapper>
  )
}

export default AddressPage
