import { createSlice } from '@reduxjs/toolkit'

import { RootState } from '..'

type InitialState = {
  error: {
    code: string | null
    description: string | null
  }
}

const initialState: InitialState = {
  error: {
    code: null,
    description: null
  }
}

const errorSlice = createSlice({
  name: 'errorSlice',
  initialState,
  reducers: {
    setError: (state, { payload }: { payload: { code: string; description: string } }) => {
      state.error.code = payload.code
      state.error.description = payload.description
    }
  }
})

export const errorSelector = (state: RootState) => state.error.error

export const { setError } = errorSlice.actions
export default errorSlice.reducer
