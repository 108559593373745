import 'twin.macro'
import '@react-pdf-viewer/core/lib/styles/index.css'

import { Viewer, Worker } from '@react-pdf-viewer/core'
import React from 'react'

const PdfFileModalViewer = ({ url = '' }: { url: string }) => {
  return (
    <div tw='w-full pb-5 sm:rounded-b-2xl'>
      <Worker workerUrl='https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js'>
        <Viewer fileUrl={url} />
      </Worker>
      {/* <Iframe width='100%' tw='min-h-[80vh]' url={url} /> */}
    </div>
  )
}
export default PdfFileModalViewer
