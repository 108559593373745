import * as React from 'react'

function BigCheckIcon() {
  return (
    <svg width={53} height={51} fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#prefix__clip0_5181_46601)'>
        <path
          d='M44.742 4.59L20.917 28.391a1.646 1.646 0 01-2.326 0L8.335 18.145.231 26.242l15.47 15.456a5.733 5.733 0 008.105 0l29.04-29.013-8.104-8.096z'
          fill='#2d7d2f'
        />
      </g>
      <defs>
        <clipPath id='prefix__clip0_5181_46601'>
          <path fill='#fff' transform='translate(.231 .077)' d='M0 0h52.615v50.769H0z' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default BigCheckIcon
