import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useOpenLandingMutation } from 'redux/api/landing.api'
import { landingTypeSelector } from 'redux/store/slices/application-slice'
import { setError } from 'redux/store/slices/error-slice'
import { setNewStep, stepSelector } from 'redux/store/slices/step-slice'

interface WebSocketComponentProps {
  access_token: string
  application_id: string
}

const WebSocketComponent: React.FC<WebSocketComponentProps> = ({ access_token, application_id }) => {
  const [landingIsOpened, { isSuccess }] = useOpenLandingMutation()
  const [, setTimeoutId] = useState<number | undefined>(undefined)
  const dispatch = useDispatch()
  const socketRef = useRef<WebSocket | null>(null)
  const landingType = useSelector(landingTypeSelector)
  const isMounted = useRef<boolean>(true)

  const writeNewStep = (step: string) => {
    dispatch(setNewStep(`${step}`))
  }

  const connectWebSocket = () => {
    const socket = new WebSocket(
      `wss://card-conveyor-ws.ipoteka-preprod.trafficwave.kz/ws/landing/${application_id}?auth_token=${access_token}&method=${landingType}`
    )

    socket.addEventListener('open', event => {
      console.log('WebSocket connection opened:', event)
    })

    socket.addEventListener('message', event => {
      console.log('Message from server:', event.data)
      const message = JSON.parse(event.data)

      if (message.is_error === 'true') {
        console.log('message run to an error', message)
        dispatch(setError({ code: message.status, description: message.message }))
      } else {
        writeNewStep(message.status)
      }
    })

    socket.addEventListener('close', event => {
      console.log('WebSocket connection closed:', event)
      // Set a timeout to reconnect after 3 seconds if the socket is closed
      setTimeoutId(setTimeout(connectWebSocket, 2000))
    })

    socketRef.current = socket
  }

  useEffect(() => {
    if (access_token && application_id && isMounted.current) {
      console.log('connect websocket')
      connectWebSocket()
    }

    return () => {
      if (socketRef.current?.readyState === 1) {
        socketRef.current.close()
      }
    }
  }, [access_token, application_id, isSuccess])

  // Use the useEffect cleanup to update the isMounted ref when the component unmounts
  useEffect(() => {
    landingType !== 'forensics' && landingIsOpened({ application_id: application_id })
    return () => {
      isMounted.current = false
    }
  }, [])

  return <></>
}

export default WebSocketComponent
