import { api } from '.'

const landingApi = api.injectEndpoints({
  endpoints: build => ({
    openLanding: build.mutation({
      query: ({ application_id }) => ({
        url: '/applications/landing/open',
        method: 'POST',
        body: { application_id }
      })
    })
  })
})
export const { useOpenLandingMutation } = landingApi
