import { createSlice } from '@reduxjs/toolkit'
import { PURGE } from 'redux-persist'

import { RootState } from '..'

type InitialState = {
  access_token: string | null
  application_id: string | null
  is_authenticated: boolean
  product_user_action_uuid: string | null
  lang: 'kk' | 'ru' | 'en' | null
  landing_type: 'forensics' | 'sumsub' | null
}

const initialState: InitialState = {
  access_token: null,
  application_id: null,
  is_authenticated: false,
  product_user_action_uuid: null,
  lang: null,
  landing_type: null
}

const applicationSlice = createSlice({
  name: 'applicationSlice',
  initialState,
  reducers: {
    setAuthData: (
      state,
      { payload }: { payload: { token: string; application_id: string; product_user_action_uuid: string } }
    ) => {
      state.access_token = payload.token
      state.application_id = payload.application_id
      state.product_user_action_uuid = payload.product_user_action_uuid
    },
    setIsAuthendicated: state => {
      state.is_authenticated = true
    },
    setSystemLanguage: (state, { payload }: { payload: { lang: 'kk' | 'ru' | 'en' | null } }) => {
      state.lang = payload.lang
    },
    setLandingType: (state, { payload }: { payload: { type: 'forensics' | 'sumsub' } }) => {
      state.landing_type = payload.type
    }
  },
  extraReducers: builder => {
    builder.addCase(PURGE, () => initialState)
  }
})

export const isAuthenticatedSelector = (state: RootState) => state.application.is_authenticated
export const applicationIdSelector = (state: RootState) => state.application.application_id
export const authDataSelector = (state: RootState) => state.application
export const systemLanguageSelector = (state: RootState) => state.application.lang
export const landingTypeSelector = (state: RootState) => state.application.landing_type

export const { setAuthData, setIsAuthendicated, setSystemLanguage, setLandingType } = applicationSlice.actions
export default applicationSlice.reducer
