import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import { enTranslations, kkTranslations, ruTranslations } from './locales'

const storedLang = localStorage.getItem('lang')

if (!storedLang) localStorage.setItem('lang', 'ru')

export const resources = {
  kk: kkTranslations,
  ru: ruTranslations,
  en: enTranslations
} as const

export const defaultNS = 'main'

i18n.use(initReactI18next).init({
  fallbackLng: localStorage.getItem('lang') ?? 'ru',
  lng: localStorage.getItem('lang') ?? 'ru',
  resources,
  ns: ['main', 'common', 'landing', 'ru'],
  defaultNS,
  interpolation: {
    escapeValue: false
  }
})

i18n.languages = ['kk', 'ru']

export default i18n
