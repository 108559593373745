import 'twin.macro'

import { AppWrapper } from 'components/UI/app-wrapper/app-wrapper'
import Header from 'components/views/header'
import { Stepper } from 'components/views/stepper'
import React from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { pathnames } from 'utils/nr-steps'

const StepLayout = () => {
  const { pathname } = useLocation()

  return (
    <>
      <Header />
      <div tw='mt-[3px] sm:mt-[40px] max-w-content mx-auto pb-[48px]'>
        {pathname !== pathnames.error &&
          pathname !== pathnames.forensics &&
          pathname !== pathnames.forensics_success && <Stepper />}

        <Outlet />
      </div>
    </>
  )
}

export default StepLayout
