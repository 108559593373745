/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState } from 'react'

export type Theme = 'dark' | 'light'

export type ThemeProviderProps = {
  initialTheme: Theme
  children: ReactNode
}

export type ThemeProviderContext = {
  theme: Theme
  isDarkTheme: boolean
  setTheme: Dispatch<SetStateAction<Theme>>
}

const getInitialTheme = (initValue: Theme = 'dark') => {
  if (typeof window === 'undefined' || !localStorage) return initValue

  const storedPrefs = localStorage.getItem('color-theme')
  if (storedPrefs) {
    return storedPrefs as Theme
  }
  const userMedia = matchMedia('(prefers-color-scheme: dark)')
  if (userMedia.matches) {
    return 'dark'
  }
  return initValue
}

const ThemeContext = createContext<ThemeProviderContext>({
  theme: 'dark',
  isDarkTheme: true,
  setTheme: () => {}
})

const useTheme = () => useContext(ThemeContext)

const ThemeProvider = ({ initialTheme, children }: ThemeProviderProps) => {
  const [theme, setTheme] = useState<Theme>(getInitialTheme(initialTheme))

  const rawSetTheme = (theme: Theme) => {
    const root = window.document.documentElement
    const isDark = theme === 'dark'

    root.classList.remove(isDark ? 'light' : 'dark')
    root.classList.add(theme)

    window.localStorage.setItem('color-theme', theme)
  }

  useEffect(() => {
    rawSetTheme(theme)
  }, [theme])

  return (
    <ThemeContext.Provider value={{ theme, setTheme, isDarkTheme: theme === 'dark' }}>{children}</ThemeContext.Provider>
  )
}

export { ThemeContext, ThemeProvider, useTheme }
