import { api } from '.'

const documentsApi = api.injectEndpoints({
  endpoints: build => ({
    getApplicationDocuments: build.query({
      query: ({ uuid }: { uuid: string }) => ({
        url: `/products/actions/${uuid}/document_templates/`,
        method: 'GET'
      })
    })
  })
})

export const { useGetApplicationDocumentsQuery } = documentsApi
