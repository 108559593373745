import BodyText from 'components/UI/body-text/body-text'
import { Button } from 'components/UI/button/button'
import { Modal } from 'components/UI/modal-window/modal'
import Title from 'components/UI/title/title'
import { ThemeContext } from 'contexts/theme-context'
import React, { useContext, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import tw from 'twin.macro'
import { pathnames } from 'utils/nr-steps'

import faceDark from './../../assets/images/fr-anim-dark.gif'
import faceLight from './../../assets/images/fr-anim-light.gif'
import SumsubBackdropContent from './sumsub-backdrop-content'

function BiometryContent({ handleContinue }: { handleContinue: () => void }) {
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const { theme } = useContext(ThemeContext)
  const { pathname } = useLocation()
  const { t } = useTranslation()
  const face = theme === 'dark' ? faceDark : faceLight
  const locale_path = pathname === pathnames.sumsub ? 'biometry:sumsub' : 'biometry:forensics'
  const onClose = () => setModalOpen(false)
  return (
    <>
      <Title variant='bold'>{t(`${locale_path}.title`)}</Title>
      <BodyText twStyle={tw`text-center text-secondary `}>
        <Trans components={{ green: <span tw='text-green font-bold' /> }}>{t(`${locale_path}.subtitle`)}</Trans>
      </BodyText>

      <div tw='flex justify-center my-5'>
        <img src={face} alt='face dark' />
      </div>
      <Button variant='secondary' onClick={() => setModalOpen(true)}>
        {t(`${locale_path}.start-button`)}
      </Button>
      <Button variant='text'>{t(`${locale_path}.cancel-button`)}</Button>
      <Modal open={modalOpen} onClose={onClose} title={t('biometry:backdrop_title')}>
        <SumsubBackdropContent
          handleContinue={() => {
            onClose()
            handleContinue()
          }}
        />
      </Modal>
    </>
  )
}

export default BiometryContent
