import 'twin.macro'

import { Moon } from 'assets/icons/MoonIcon'
import { Sun } from 'assets/icons/SunIcon'
import { Toggle } from 'components/UI/toggle/toggle'
import { ThemeContext } from 'contexts/theme-context'
import React, { useCallback } from 'react'

const ThemeToggle = () => {
  const { theme, setTheme } = React.useContext(ThemeContext)

  const isDark = useCallback(() => theme === 'dark', [theme])

  const toggleHandler = useCallback(() => {
    setTheme(isDark() ? 'light' : 'dark')
  }, [setTheme, isDark])
  return (
    <div>
      <Toggle
        toggleHandler={toggleHandler}
        isOn={isDark()}
        icons={[<Moon theme={theme} key='moon' />, <Sun theme={theme} key='sun' />]}
      />
    </div>
  )
}

export default ThemeToggle
