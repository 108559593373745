import SuccessDarkIcon from 'assets/icons/success-dark.svg?component'
import SuccessLightIcon from 'assets/icons/success-light.svg?component'
import { ThemeContext, ThemeProviderContext } from 'contexts/theme-context'
import { Fragment, ReactNode, useContext, useId } from 'react'
import { Control, FieldValues, Path, useController } from 'react-hook-form'
import tw, { TwStyle } from 'twin.macro'

type CheckboxProps<T extends FieldValues> = {
  name: Path<T>
  control: Control<T>
  rules?: object
  disabled?: boolean
  labelStyles?: TwStyle
  checkboxStyles?: TwStyle
  children?: ReactNode
}

const Checkbox = <T extends FieldValues>({
  name,
  control,
  rules,
  disabled,
  labelStyles,
  checkboxStyles,
  children
}: CheckboxProps<T>) => {
  const checkboxId = useId()

  const themeContext = useContext(ThemeContext)
  const { theme } = themeContext as ThemeProviderContext

  const {
    field: { value, onChange, name: fieldName, ref }
  } = useController({ name, control, rules })

  return (
    <label htmlFor={checkboxId} css={[tw`inline-flex leading-[inherit] mt-4 cursor-pointer items-center`, labelStyles]}>
      <div
        css={[
          tw`inline-flex cursor-pointer p-[3px] mr-3`,
          disabled && tw`opacity-30 cursor-not-allowed`,
          checkboxStyles
        ]}
      >
        {value ? (
          <Fragment>
            {theme === 'dark' ? <SuccessDarkIcon tw='inline-flex ' /> : <SuccessLightIcon tw='inline-flex' />}
          </Fragment>
        ) : (
          <span tw='inline-flex w-[1.125em] h-[1.125em] border-[0.09375em] rounded-[0.25em] '></span>
        )}
      </div>
      {children}
      <input
        id={checkboxId}
        type='checkbox'
        name={fieldName}
        disabled={disabled}
        checked={value}
        onChange={onChange}
        ref={ref}
        tw='appearance-none w-0 h-0 absolute'
      />
    </label>
  )
}

export { Checkbox }
